import React from "react";

const UICloud = () => {
  return (
    <>
      <div className="absolute top-[0px] left-[-342px] [filter:blur(383.4px)] rounded-[50%]  bg-skyblue-200 w-[525px] h-[510px]" />
    </>
  );
};

export default UICloud;
